<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="max-w-sm mx-auto group">
        <router-link
          to="/"
          class="
            w-full
            inline-flex
            items-center
            text-sm
            font-medium
            text-indigo-600
            group-hover:text-primary-teal
            transition
            duration-300
            ease-in-out
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="
              h-6
              w-6
              text-indigo-500
              group-hover:text-primary-teal
              mr-2
              group-hover:-translate-x-2
              transform
              transition
              duration-300
              ease-in-out
            "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          Pealehele
        </router-link>
      </div>
      <div
        class="
          flex flex-col
          justify-center
          h-full
          mx-auto
          w-full
          max-w-sm
          lg:w-96
        "
      >
        <h2 class="text-3xl font-extrabold text-black text-center">
          Sisselogimine
        </h2>
        <p class="text-center mt-2 text-base font-normal text-gray-700">
          Pole veel liitunud?
          <router-link
            to="/sign-up"
            class="text-indigo-600 text-base font-medium underline"
            >Loo konto siin</router-link
          >
        </p>
        <div class="mt-8">
          <form @submit.prevent="doLogin" class="space-y-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                E-mail
              </label>
              <div class="mt-1">
                <input
                  v-model="email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2.5
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    transition
                    duration-300
                    ease-in-out
                    sm:text-sm
                  "
                />
              </div>
              <div
                v-if="false"
                class="
                  flex
                  items-center
                  font-normal
                  tracking-wide
                  text-red-500 text-xs
                  mt-1.5
                "
              >
                emailerror
              </div>
            </div>

            <div class="space-y-1">
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Parool
              </label>
              <div class="mt-1">
                <input
                  v-model="password"
                  id="password"
                  name="password"
                  type="password"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2.5
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    transition
                    duration-300
                    ease-in-out
                    sm:text-sm
                  "
                />
              </div>
              <div
                v-if="false"
                class="
                  flex
                  items-center
                  font-normal
                  tracking-wide
                  text-red-500 text-xs
                  mt-1.5
                "
              >
                passworderror
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="
                  w-full
                  flex
                  justify-center
                  py-2.5
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                  transition
                  duration-300
                  ease-in-out
                "
              >
                Logi sisse
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-contain max-w-5xl mx-auto"
        src="../assets/images/eesti.jpeg"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, watchEffect } from "vue";

import { useUserStore } from "@/store/UserStore";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";

export default defineComponent({
  setup() {
    const email = ref<string | null>();
    const password = ref<string | null>();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const userInfo = computed(() => userState.userInfo);
    const router = useRouter();
    watchEffect(() => {
      if (!userInfo.value) return;
      const { municipalitySlug: userMunicipality } = userInfo.value;
      userMunicipality
        ? router.push({
            name: RouteName.MUNICIPALITY_HOME,
            params: { municipalitySlug: userMunicipality },
          })
        : router.push({ name: RouteName.HOME });
    });
    const doLogin = async () => {
      if (!email.value || !password.value) return;
      await userStore.logIn({ email: email.value, password: password.value });
    };

    return {
      email,
      password,
      doLogin,
    };
  },
});
</script>
