
import { ref, defineComponent, computed, watchEffect } from "vue";

import { useUserStore } from "@/store/UserStore";
import { useRouter } from "vue-router";
import { RouteName } from "@/router/types";

export default defineComponent({
  setup() {
    const email = ref<string | null>();
    const password = ref<string | null>();
    const userStore = useUserStore();
    const userState = userStore.getState();
    const userInfo = computed(() => userState.userInfo);
    const router = useRouter();
    watchEffect(() => {
      if (!userInfo.value) return;
      const { municipalitySlug: userMunicipality } = userInfo.value;
      userMunicipality
        ? router.push({
            name: RouteName.MUNICIPALITY_HOME,
            params: { municipalitySlug: userMunicipality },
          })
        : router.push({ name: RouteName.HOME });
    });
    const doLogin = async () => {
      if (!email.value || !password.value) return;
      await userStore.logIn({ email: email.value, password: password.value });
    };

    return {
      email,
      password,
      doLogin,
    };
  },
});
